'use client' // Error boundaries must be Client Components

import React, { useEffect } from 'react'
import NotFoundImage from "@/images/content/404.webp";
import ShapeHero from "../../../public/static-images/shape-hero.svg";
import LineHero from "../../../public/static-images/line-hero.svg";
import {loadTranslations, t} from "@/util/translations/getTranslations";
import {store} from "@/redux/store";
import ErrorStackParser from 'error-stack-parser'

interface ErrorProps {
    error: Error & { digest?: string }
}

export default function Error(props: ErrorProps) {
    const {error} = props;
    const locale: string = store.getState().translations.locale || process.env.DEFAULT_LOCALE || '';

    // Clean up the fileName string and only return everything after 'src/'.
    function getFilteredFileName(fileName: string | undefined): string {
        if (fileName) {
            const parts = fileName.split('src/');
            return `src/${parts[1]}`;
        }

        return '';
    }

    useEffect(() => {
        if (process.env.NEXT_PUBLIC_APP_ENV === 'development' || process.env.NEXT_PUBLIC_APP_ENV === 'staging') {
            // Log the error to an error reporting service
            console.error(error)
        } else {
            console.error('Error digest: ', error.digest);
            loadTranslations(locale);
        }
    }, [error])

    if (process.env.NEXT_PUBLIC_APP_ENV === 'development' || process.env.NEXT_PUBLIC_APP_ENV === 'staging') {
        const stackFrames = ErrorStackParser.parse(error)

        return (
            <div className="min-h-screen flex items-center justify-center bg-grey-100">
                <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
                    <h2 className="text-2xl font-bold mb-4">Application Error</h2>

                    <p className="text-lg mb-6 bg-red/25 text-red px-4 py-2 border-l-2 border-red">{error.message}</p>

                    <div className="p-4 rounded-lg mb-6 border border-grey-600">
                        <h3 className="text-lg font-semibold mb-2">Stack Trace:</h3>
                            {stackFrames.map((frame, index) => (
                                <div key={index} className="text-sm mb-2">
                                    <span className="font-mono break-words">{frame.functionName || '(anonymous function)'}</span>
                                    <div className="text-grey-600 break-words">
                                        at {getFilteredFileName(frame.fileName)}:{frame.lineNumber}:{frame.columnNumber}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="h-screen relative overflow-hidden">
            <div className="relative h-full w-full top-0 left-0">
                <div className="relative h-full w-full transition duration-300">
                    <img className="h-full absolute w-full object-cover"
                         alt="500"
                         src={NotFoundImage.src}/>
                </div>
            </div>
            <div
                className="w-[570px] lg:w-[624px] xl:w-1/2 max-w-[1000px] h-auto absolute bottom-0 animate-start-fade-in">
                <ShapeHero className="w-[91%] h-auto absolute bottom-0"/>
                <LineHero className="w-full h-auto relative"/>
                <div
                    className="absolute left-0 bottom-0 pl-5 xl:pl-10 xxl:pl-20 pb-5 xl:pb-10 xxl:pb-20 w-[60%] lg:w-[70%] xl:w-[80%] mb-14 sm:mb-7">
                    <div className="text-white text-4xl lg:text-5xl xl:text-6xl xxl:text-7xl font-semibold mb-4">
                        {t('app.not_found.title')}<br/>
                        <span className="text-accent-100">{t('app.error.subtitle')}</span>
                    </div>
                    <div className="text-white mb-4"
                         dangerouslySetInnerHTML={{
                             __html: t('app.error.description'),
                             }}></div>
                        <a className="btn btn-tertiary"
                              title={t('app.not_found.button')}
                              href={`/${locale}`}>
                            {t('app.not_found.button')}
                        </a>
                    </div>
                </div>
            </div>
    )
}
